// Variable overrides
$sidebar-width: 20rem;
$sidebar-bg: #fafafa;
$sidebar-nav-group-bg: #fafafa;

$sidebar-backdrop-bg: #000;
$sidebar-nav-group-indicator-color: #091e42;

$sidebar-nav-title-transition: rgba(0, 0, 0, 100);
$sidebar-nav-link-color: #091e42;
$sidebar-nav-link-icon-color: #091e42;

$sidebar-nav-link-hover-color: #091e42;
$sidebar-nav-link-hover-icon-color: #091e42;
$sidebar-nav-link-hover-bg: #091e4210;

$sidebar-nav-link-active-color: #091e42;
$sidebar-nav-link-active-icon-color: #091e42;
$sidebar-nav-link-active-bg: #091e4210;

$input-focus-border-color: #aaaaaa;
$cui-input-focus-color: #00000000;
$cui-input-focus-bg: #00000000;
$input-focus-box-shadow: #00000000;
$input-disabled-bg: #f2f2f2;
$input-disabled-border-color: #dddddd;

$form-check-input-checked-bg-color: #2f85f7;
$form-check-input-checked-border-color: #2f85f7;

$btn-link-hover-color: #2f85f7;
$btn-link-color: #2f85f7;
$btn-transition: #2f85f7;
